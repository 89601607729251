<template>
  <v-app>
    <v-container fluid
      style="height: 100%; padding: 0px">
      <router-view></router-view>
      <loader ref="loader" />
      <msg-box ref="msgBox" />
      <confirm ref="confirm"
        :toolbar="toolbar" />
      <alert ref="alert" />
      <register ref="registerUser" />
      <pdf-print ref="pdfPrint"></pdf-print>
      <maska ref="mask" />
      <msg-box-html ref="msgBoxHtml" />
      <help ref="help" />
      <account-delete ref="accountDelete"></account-delete>
    </v-container>
  </v-app>
</template>

<script>
import state from '@/state'
import MsgBox from '@/components/MsgBox.vue'
import MsgBoxHtml from '@/components/MsgBoxHtml.vue'
import Confirm from '@/components/Confirm'
import Loader from '@/components/Loader'
import Alert from '@/components/Alert'
import Help from '@/components/Help'
import Register from '@/components/Register'
import pdfPrint from '@/components/PdfPrintModal'
import Maska from '@/components/Maska'
import EventBus from '@/plugins/event-bus'
import AccountDelete from '@/components/AccountDelete'

export default {
  name: 'App',
  components: {
    MsgBox,
    Confirm,
    Loader,
    Alert,
    Register,
    pdfPrint,
    Maska,
    MsgBoxHtml,
    Help,
    AccountDelete
  },

  data: function () {
    return { toolbar: false }
  },
  mounted () {
    this.$i18n.locale = state.getLocale()
    this.$vuetify.theme.dark = state.isDark() === 'true'
    EventBus.$on('helper-popup', (mode, tag) => {
      this.$refs.help.showHelp(mode, tag)
    })

    EventBus.$on('deleteAccount', () => {
      this.$refs.accountDelete.open()
    })
  },
  provide () {
    return {
      showMsgBox: config => {
        this.$refs.msgBox.show(config)
      },
      showAlert: config => {
        this.$refs.alert.show(config)
      },
      showLoader: (text = undefined, custom = undefined) => {
        this.$refs.loader.show(text, custom)
      },
      hideLoader: () => {
        this.$refs.loader.hide()
      },
      showMask: (currentVersion = undefined, latestVersion = undefined) => {
        this.$refs.mask.show(currentVersion, latestVersion)
      },
      hideMask: () => {
        this.$refs.mask.hide()
      },
      registerUserForm: () => {
        this.$refs.registerUser.open()
      },
      addCompanyForm: () => {
        this.$refs.editCompany.open()
      },
      openPdfPrint: pdf => {
        this.$refs.pdfPrint.open(pdf)
      },
      closePdfPrint: () => {
        this.$refs.pdfPrint.close()
      },
      confirm: config => {
        if (config && config.options && config.options.toolbar) {
          this.toolbar = true
        } else {
          this.toolbar = false
        }
        return this.$refs.confirm.open(config.title, config.message, config.options)
      },
      confirmClose: () => {
        return this.$refs.confirm.close()
      },
      showMsgBoxHtml: config => {
        this.$refs.msgBoxHtml.show(config)
      },
      accountDelete: () => {
        return this.$refs.accountDelete.open()
      }
    }
  }
}
</script>
<style lang="scss">
/* general (no scoped) aplication style here */
.slider--home {
  position: absolute;
  width: 50%;
  height: 100%;
  width: 100%;
  right: 50%;
  border-bottom: 2px solid #dcdcdc;
  z-index: 0;
}

.top-bar {
  position: fixed;
  top: 0;
  height: 250px;
  width: 100%;
  overflow: hidden;
  z-index: 15;
  background: #e9ebee;
}

.mobile {
  width: 100%;
  float: right;
  height: 100%;
}

.mobile-card {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}

.mobile-dialog-card {
  position: fixed;
  top: 0;
  height: 100%;
  max-width: 550px;
}

.fixed-side {
  grid-area: nav;
  height: 100vh;
  position: sticky;
  top: 0;
  bottom: 0;
}

.one-liner-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky;
  /* for Safari */
  top: 0.1px;
  z-index: 3;
  width: 100%;
}

html {
  overflow-y: auto;
}

.status-ok {
  border-left: 4px solid var(--v-success-base);
}

.status-ok-exp {
  border-left: 30px solid var(--v-success-base);
}

.status-failed {
  border-left: 4px solid var(--v-error-base);
}

.status-failed-exp {
  border-left: 30px solid var(--v-errorReceipt-base);
}

.status-warn-exp {
  border-left: 30px solid var(--v-warnReceipt-base);
}

.status-warn-after-exp {
  border-left: 30px solid var(--v-warnAfterReceipt-base);
}

.status-processing {
  border-left: 4px solid var(--v-info-base);
}

.status-processing-exp {
  border-left: 30px solid var(--v-info-base);
}

.status-processing-partial-exp {
  border-left: 30px solid #fcf005;
}

.status-failed-expired {
  border-left: 30px solid purple;
}

.status-uncollectible {
  border-left: 30px solid rgb(239, 102, 102);
}

.margin-ok {
  background-color: var(--v-success-base) !important;
}

.margin-not-ok {
  background-color: var(--v-error-base) !important;
}

.status-reversed {
  border-left: 30px solid #448aff;
}

.status-storno {
  border-left: 30px solid #540000;
}

// .btn-warn-after-exp {
//   background-color: var(--v-warnAfterReceipt-base);
// }
@import "@/sass/variables.scss";
@import "@/sass/datepicker.scss";

$typoOptions: display-4, display-3, display-2, display-1, headline, title,
  subtitle-1, subtitle-2, body-1, body-2, caption, overline, text-h6, text-h5,
  text-h4, text-h3, text-h2, text-h1;

#app {
  font-family: $body-font-family !important;

  @each $typoOption in $typoOptions {
    .#{$typoOption} {
      font-family: $body-font-family !important;
    }
  }
}
</style>
